import { createRouter, createWebHistory } from 'vue-router';
import JobsView from '@/components/JobsView.vue';
import NewsView from '@/components/NewsView.vue';

// Define your routes
const routes = [
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobsView,
  },
  {
    path: '/news',
    name: 'News',
    component: NewsView,
  },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes, // Pass the routes
});

export default router;
