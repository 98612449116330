<template>
  <div id="app" class="app-container">
    <!-- Navbar from Bulma -->
    <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <h1 class="title is-3 has-text-info">
            <span class="open-symbol">&lt;</span><span class="cursor"></span>SI Software
          </h1>
        </router-link>

        <!-- Hamburger Menu for Mobile -->
        <a
          role="button"
          class="navbar-burger"
          :class="{ 'is-active': isActive }"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': isActive }">
        <div class="navbar-end">
          <router-link class="navbar-item has-text-white" to="/">Home</router-link>
          <router-link class="navbar-item has-text-white" to="/jobs">Jobs</router-link>
          <router-link class="navbar-item has-text-white" to="/news">News</router-link>
        </div>
      </div>
    </nav>

    <!-- Main content and router view -->
    <div class="content-container">
      <!-- Home page content -->
      <div v-if="$route.path === '/'">
        <section class="hero is-fullheight-with-navbar is-black">
          <div class="hero-body">
            <div class="container has-text-centered">
              <h2 class="title is-1 has-text-info">
                <span class="open-symbol">&lt;</span><span class="cursor"></span>SI Software
              </h2>

              <!-- Cards for linking to company projects -->
              <div class="columns is-centered is-mobile is-multiline mt-6">
                <!-- Card 1 -->
                <div class="column is-full-mobile is-one-third-tablet">
                  <div class="card has-background-dark">
                    <div class="card-content">
                      <p class="title is-4">Thryve</p>
                      <p class="subtitle is-6">Innovative Retention, Engagement, and Upskilling Platform</p>
                      <div class="content">
                        Keep your workforce engaged, skilled, and working with Thryve. #ThrivewithThryve
                        <br />
                        <a href="https://www.thryveconnect.com" target="_blank" class="has-text-info">Visit Thryve</a>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Card 2 -->
                <div class="column is-full-mobile is-one-third-tablet">
                  <div class="card has-background-dark">
                    <div class="card-content">
                      <p class="title is-4">Vereon</p>
                      <p class="subtitle is-6">Discover Deep Insights into Public Companies</p>
                      <div class="content">
                        Save time and gain in-depth insights with Vereon
                        <br />
                        <a href="https://sec-strategy-app.vercel.app/" target="_blank" class="has-text-info">Visit Vereon</a>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Card 3 -->
                <div class="column is-full-mobile is-one-third-tablet">
                  <div class="card has-background-dark">
                    <div class="card-content">
                      <p class="title is-4">CustomerIQ</p>
                      <p class="subtitle is-6">Increase Your Customer IQ</p>
                      <div class="content">
                        Stay on top of your customer pain points with our intuitive AI platform.
                        <br />
                        <a href="https://customer-discovery-app.vercel.app/" target="_blank" class="has-text-info">Visit CustomerIQ</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Router View for Jobs and News pages -->
      <router-view></router-view>
    </div>

    <!-- Footer -->
    <footer class="footer has-background-black">
      <div class="content has-text-centered">
        <p class="has-text-grey-light">© 2024 SI Software - Innovative and Socially Impactful Solutions</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style scoped>
/* Set up flexbox layout for footer positioning */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex-grow: 1; /* Make content area grow and push footer down */
}

footer {
  background-color: #000; /* Ensure footer matches dark theme */
  padding: 20px;
  color: #e0e0e0;
  text-align: center;
}

/* Custom styling alongside Bulma */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Rajdhani', sans-serif;
  background-color: #000;
}

.cursor::after {
  content: '|';
  color: #9b59b6;
  animation: blink-cursor 1.5s steps(1) infinite;
}

.open-symbol {
  color: #00FFCC; /* Neon teal open symbol */
}

/* Cursor blinking animation */
@keyframes blink-cursor {
  50% {
    opacity: 0;
  }
}

.title.is-1 {
  animation: fadeIn 1.2s forwards;
}

.columns {
  margin-top: 40px; /* Add space between the logo and cards */
}

.card {
  background-color: #1a1a1a; /* Dark background for cards */
  color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow for depth */
  min-height: 300px;
}

.card .title {
  color: #00FFCC; /* Neon color for card titles */
}

.card .subtitle {
  color: #9b59b6; /* Muted purple for subtitles */
}

.card a {
  color: #00FFCC; /* Neon teal for links */
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero-body {
    padding: 1.5rem;
  }

  .columns {
    margin-top: 20px; /* Reduce margin for mobile devices */
  }

  .card {
    padding: 10px; /* Adjust card padding for mobile devices */
  }
}
</style>
