<template>
  <div class="section">
    <h1 class="title has-text-info">Jobs at SI Software</h1>
    <p>Explore the latest career opportunities with us.</p>
  </div>
</template>

<script>
export default {
  name: 'JobsView',
};
</script>

<style scoped>
.section {
  padding: 50px;
}
</style>
