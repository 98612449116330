<template>
  <div class="section">
    <h1 class="title has-text-info">SI Software News</h1>
    <p>Stay updated with the latest news and announcements from SI Software.</p>
    
    <!-- Press Release Content -->
    <div class="press-release">
      <h2 class="subtitle has-text-weight-bold">SI Software Announces Commitment to Innovation and Social Impact</h2>
      <p><strong>[October 2024]</strong> – SI Software, a leading innovator in the tech industry, is proud to announce its mission of delivering cutting-edge software solutions that drive both <strong>technological innovation</strong> and <strong>social impact</strong>. With a focus on preparing individuals and organizations for the future of work, SI Software’s suite of products is designed to empower users with tools that enhance <strong>engagement, retention, upskilling</strong>, and <strong>data-driven insights</strong>.</p>
      
      <p>"Our ethos is centered on using technology as a force for good," said <strong>founder Jerome</strong>. "We are committed to developing solutions that not only drive business success but also contribute positively to society."</p>
      
      <p>Key products in the SI Software portfolio include:</p>
      <ul>
        <li><strong>Thryve</strong>: An AI-powered platform focused on <strong>employee retention, engagement</strong>, and <strong>upskilling</strong>.</li>
        <li><strong>Vereon</strong>: A data-driven tool offering <strong>deep insights into public companies</strong>, enabling smarter, faster business decisions.</li>
        <li><strong>CustomerIQ</strong>: A platform to help businesses <strong>understand and address customer pain points</strong> using intuitive AI-driven insights.</li>
      </ul>
      
      <p>SI Software remains committed to its vision of shaping the future of work by offering scalable, flexible, and future-ready solutions. Stay tuned to SI Software News for more updates on our latest developments.</p>
      
      <p>For more information, visit SI Software News or contact us at <strong>jbreview@outlook.com</strong>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsView',
};
</script>

<style scoped>
.section {
  padding: 50px;
}

.press-release {
  margin-top: 30px;
}

.press-release h2 {
  margin-bottom: 20px;
}

.press-release ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
